









































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { DiscoveryViewmodel } from '../../viewmodels/discovery-viewmodel'

@Observer
@Component({
  components: {
    'community-list': () => import('@/modules/community/home/components/community-list.vue'),
    'community-item': () => import('@/modules/community/home/components/community-item.vue'),
  },
})
export default class extends Vue {
  @Inject() vm!: DiscoveryViewmodel
}
